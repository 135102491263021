.container {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;

  /* Declaring some common styles*/
  .auto {
    margin: 0 auto;
  }

  .flexCol {
    display: flex;
    flex-direction: column;
  }

  .flexColReverse {
    display: flex;
    flex-direction: column-reverse
  }

  .flexRowReverse {
    display: flex;
    flex-direction: row-reverse
  }

  .flex {
    display: flex;
  }

  .alignItemsCenter {
    align-items: center;
  }

  .justContentCenter {
    justify-content: center;
  }

  .justContentSpaceBtw {
    justify-content: space-between;
  }
  /************/

  // HomeContainer
  .homeContainer {
    background: url('./assets/background.webp'), var(--background);
    background-size: cover;
    background-repeat: no-repeat;

    a {
      color: var(--palm);
      font-weight: 600;
      font-size: 1.5rem;
    }

    .homeContent {
      padding-top: 170px;
      width: var(--w--90);
      @extend .auto;

      .homeText {
        @extend .flexCol;
        gap: 2rem;

        .homeTitle {
          font-size: 9vw;
          line-height: 1.4;
          font-weight: 800;
          color: #fff;
        }

        .homePara {
          margin-top: 0.2rem;
          color: #fff;
          line-height: 1.5;
        }
      }

      .homeImage {
        max-width: 100%;
        margin: 2rem auto 10rem;
      }

      @media screen and (min-width: 768px) {
        width: var(--w--80);
      }

      @media screen and (min-width: 956px) {
        padding-top: 220px;
        width: 93%;
        display: flex;

        .homeText {
          gap: 1.5rem;
  
          .homeTitle {
            font-size: 5.5vw;
          }
  
          .homePara {
            font-size: 1.25rem;
            max-width: 90%;
          }
        }

        .homeImage {
          width: 40%;
        }
      }
    }
  }

  // HomeSection
  .homeSection {
    position: relative;
    top: -5rem;
    width: var(--w--90);
    @extend .auto;

    .homeAbout {

      .homeAboutPre {
        @extend .flex;

        .homeAboutImage {
          width: 60%;
          height: 50%;
        }

        .homeAboutTitle {
          padding-top: 6rem;
          font-size: 5.5vw;
          color: var(--text--green);
          font-weight: 700;

          @media screen and (min-width: 768px) {
            padding-top: 8rem;
            font-size: 4vw;
          }
        }
      }

      .homeAboutText {
        @extend .auto;
        color: var(--text--dark);
        
        .homeAboutTitle {
          display: none;
        }

        .homeAboutDescription {
          @extend .flexCol;
          padding: 1rem;
          gap: 0.25rem;
        }
      }
    }

    @media screen and (min-width: 768px) {
      width: var(--w--80);
    }

    @media screen and (min-width: 956px) {
      width: 60%;

      .homeAbout {
        display: flex;
        background-color: #fff;
        box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.15);
        border-radius: 80px;
        margin-left: 3rem;
        padding: 40px 40px 40px 150px;

        .homeAboutPre {

          .homeAboutImage {
            position: absolute;
            width: 100%;
            max-width: 256px;
            height: auto;
            top: -2.5rem;
            left: -4%;
          }

          .homeAboutTitle {
            display: none;
          }
        }

        .homeAboutText {
          
          .homeAboutTitle {
            display: unset;
            color: var(--text--green)
          }
  
          .homeAboutDescription {
            @extend .flexCol;
            padding: 1rem;
            gap: 0.25rem;
          }
        }
      }
    }
  }

  .offerSection {
    background-color: #094e27;
    background-image: url('./assets/background_overlay.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 95%;
    @extend .flexCol;
    @extend .alignItemsCenter;
    border-radius: 12px;
    margin: 240px auto 40px;
    gap: 2rem;
    padding: 3rem 2.5rem;

    .offerContent {
      text-align: center;
      color: #fff;

      .offerTitle {
        font-size: 4.25vw;
        font-weight: 600;
        margin-bottom: 1rem;
        line-height: 1.33;
      }
    }

    @media screen and (min-width: 956px) {
      width: 85%;
      background-size: cover;
      flex-direction: row;
      justify-content: space-between;
      margin: 240px auto 180px;
      padding: 3rem 3rem 3rem 5rem;

      .offerContent {
        text-align: left;
        width: 60%;
        .offerTitle {
          font-size: 1.5rem;
        }
      }
      
    }
  }

  .footer {
    width: 100%;
    color: #fff;
    background: #000;

    .footerAction {
      @extend .flexCol;
      @extend .alignItemsCenter;

      .footerActionImage {
        margin: 4rem auto;
      }

      .footerActionContent {
        @extend .footerAction;
        gap: 1rem;
      }
    }

    .footerContact {

      .footerContactContent {
        display: none;
      }
    }

    .footerSocial {
      width: 90%;
      margin: 4rem auto 0;
      @extend .flexCol;
      @extend .alignItemsCenter;
      gap: 3rem;
      padding-bottom: 1.5rem;

      .footerSocialContent {
        @extend .flexCol;
        gap: 1rem;

        .footerSocialLinks {
          @extend .flex;
          @extend .alignItemsCenter;
          @extend .justContentCenter;
          gap: 1rem;
          font-size: 1.5rem;
          color: #fff;
        }

        .footerSocialLinkItem {
          width: 40px;
          height: 40px;
          border: 1px solid var(--palm);
          border-radius: 50%;
          @extend .flex;
          @extend .alignItemsCenter;
          @extend .justContentCenter;
          transition: background-color ease-in-out 0.3s;

          &:hover {
            border: 1px solid var(--white);
          }

          .footerSocialLink {
            color: #fff;
            padding-top: 0.4rem;
            transition: color ease-in-out 0.3s;

            &:hover {
              color: var(--palm);
            }
          }
        }
      }

      @media screen and (min-width: 956px) {
        width: 85%;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-top: 2rem;
      }
    }

    @media screen and (min-width: 956px) {

      .footerAction {
        flex-direction: row;
        width: 85%;
        margin: 5rem auto 0;
        justify-content: space-between;
        border-bottom: 1px solid #494949;
        line-height: 0;

        .footerActionImage {
          margin: 3.5rem auto 0;
        }
  
        .footerActionContent {
          justify-content: flex-end;
          border-bottom: unset;
        }
      }

      .footerContact {

        .footerContactContent:first-of-type {
          display: none;
        }

        small.footerContactContent:last-of-type {
          display: flex;
          width: 85%;
          margin: 4rem auto 0;

          .footerContactMenu {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 3rem;
            row-gap: 1.5rem;

            .footerItem {
              .footerLink {
                color: #fff;
                font-weight: 500;
                transition: color ease-in-out 0.3s;

                &:hover {
                  color: var(--palm);
                }
              }
            }
          }
        }
      }
  
    }

    ul {
      list-style-type: none;
    }
  }

  @media screen and (min-width: 956px) {
    background-image: url('./assets/first_bg.png'), url('./assets/join_sec.png'), url('./assets/top_right.png'), url('./assets/mid_left.png'), url('./assets/bottom_right.png');
    background-repeat: no-repeat;
    background-size: 25%;
    background-position: 100% 12%, 0 36%, 102% 46%, 0 59%, 102% 79%;
  }

  // Span styles
  .tint {
    color: var(--palm);
  }

  .bold {
    font-weight: 600;
  }
}
