.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex {
  display: flex;
}

.flexCol {
  @extend .flex;
  flex-direction: column;
}

.flexRow {
  @extend .flex;
  flex-direction: row;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

.stockContainer {
  background: var(--secondary-200);
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  padding: 13vh 1rem;

  .title {
    font-size: var(--text-20);
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--primary-700);
  }

  .cartsDiv {
    @extend .flexCol;

    .storeCard {
      @extend .flex;
      @extend .justContentSpaceBtw;
      gap: 1rem;
      border-radius: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      background:#f1edd0;
      padding: 1.25rem 0.75rem;
      margin-top: 0.5rem;
      color: #42423a;

      .orderInfo {
        width: 50%;
        @extend .flexCol;
        @extend .justContentSpaceBtw;
        .text,
        .price {
          @extend .flex;
          @extend .justContentSpaceBtw;
          @extend .alignItemsCenter;
          span {
            font-weight: 600;
            text-transform: capitalize;
          }

          .price {
            font-weight: 700;
          }
        }
      }

      .actionDiv {
        @extend .flexCol;
        @extend .justContentSpaceBtw;
        font-weight: 600;

        .view {
          color: #82806C;
          align-self: flex-end;
          font-size: var(--text-14);
          transition: all 0.2s;
          font-weight: 700;
          border: none;
          background: transparent;
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-skip-ink: none;
          text-decoration-thickness: auto;
          text-underline-offset: auto;
          text-underline-position: from-font;

          &:active {
            transform: scale(0.9);
          }
        }

        .addUp {
          .btn {
            // background: var(--gradient);
            border: 1px solid var(--primary-600);
            background: transparent;
            font-size: var(--text-14);
            display: block;
            border-radius: 8px;;
            color: var(--primary-600);
            padding: 0.55rem 0.22rem;
            transition: all 0.2s;
            width: fit-content;

            &:active {
              transform: scale(0.9);
            }

            &:disabled {
              color: #9e9d8a;
              border: 1px solid #9e9d8a;
              background: #d2d2c9;
            }
          }

          .dropzone {
            position: fixed;
            width: 100vw;
            min-height: 100vh;
            background: rgba(0, 0, 0, 0.5);
            z-index: 600;
            top: 0;
            left: 0;

            &.hide {
              display: none;
            }
          }
          
          .form {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background:  var(--white);
            border-radius: 30px;
            box-shadow: 0px 8px 20px 8px rgba(0, 0, 0, 0.08);
            @extend .flexCol;
            width: 75%;
            line-height: 1.4;
            border: none;
            gap: 1rem;
            align-items: center;
            justify-content: center;
            color: var(--text--grey);
            font-weight: 500;
            padding: 2rem;
            text-align: center;

            .btnD {
              @extend .btn;
              background: var(--gradient);
              color: white;
              padding: 0.75rem;
              margin-top: 1rem;
            }
          
            .icon {
              font-size: 5rem;
              color: var(--primary-300);
            }
          
            .link {
              padding: 1rem;
              color: white;
              border: none;
              border-radius: 8px;
              cursor: pointer;
              width: 100%;
              margin: 1rem auto 1.5rem;
              background: var(--gradient);
              transition: transform 0.2s;
          
              &:active {
                transform: scale(0.9);
              }
            }
          
            &.hide {
              display: none;
            }
          }
          
          .article {
            color: var(--white);
            padding: 1.25rem;
            border-radius: 8px;
            font-weight: 600;
            background:  var(--primary-300);
            box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.15);
          }
        }
      }
    }
  }

  .empty {
    @extend .flexCol;
    @extend .alignItemsCenter;
    @extend .justContentCenter;
    gap: 1rem;
    margin-top: 5rem;

    .icon {
      font-size: var(--title-32);
      color: var(--primary-700);
      height: 80px;
      width: 80px;
      padding: 1rem;
      background-color: white;
      border-radius: 50%;
    }

    .text {
      max-width: 70%;
      margin: 0 auto;
      text-align: center;
    }
  }
}