.wrapper {
  position: fixed;
  background: var(--primary-300);
  box-shadow: 0px -5px 16px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 11vh;
  width: 100%;
  padding: 0 1rem;
  left: 0;
  bottom: 0;
  z-index: 99000000000000000000000000000000000000000000000000000000000000;

  .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 58px;
    gap: 0.5rem;
    color: var(--white);

    .icon {
      font-size: 1.45rem;
    }

    .name {
      font-size: 0.8rem;
    }

    &.active {
      background: #73b466;
      box-shadow: 0px 8.923px 13.385px 0px rgba(0, 0, 0, 0.08);
      border-radius: 8.923px;
      padding: 0.5rem;
    }
  }
}
