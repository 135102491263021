// HomeSection
/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}
/************/

.homeSection {
  width: var(--w--90);
  @extend .auto;
  background-image: url('../../images/bg_cookies.svg');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  .homeAbout {

    .homeAboutPre {
      @extend .flexCol;
      flex-direction: column-reverse;
      text-align: center;

      .homeAboutTitle {
        padding: 2rem 0 1rem;
        font-size: 5.5vw;
        color: var(--primary-700);
        text-shadow: 0 1px 4px var(--primary-900);
        font-weight: 700;
        margin: 0 auto;
        max-width: 80%;

        @media screen and (min-width: 768px) {
          padding-top: 8rem;
          font-size: 4vw;
        }
      }
    }

    .homeAboutText {
      @extend .auto;
      color: var(--gray-900);
      padding-bottom: 4rem;
      
      .homeAboutTitle {
        display: none;
      }

      .homeAboutDescription {
        @extend .flexCol;
        margin-left: 1.5rem;
        padding: 1rem;
        gap: 1rem;

        .homeAboutDescriptionItem {
          font-size: var(--text-18);
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    width: var(--w--80);

    .homeAboutImage {
      max-width: 50%;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 956px) {
    width: var(--w--100);
    padding: 5rem 5%;
    .homeAbout {
      display: flex;
      background-color: #fff;
      border-radius: 80px;
      padding: 3rem 10%;
      width: 90%;
      margin: 0 auto;

      .homeAboutPre {
        align-items: center;
        justify-content: center;

        .homeAboutImage {
          max-width: 100%;
          height: auto;
        }

        .homeAboutTitle {
          display: none;
        }
      }

      .homeAboutText {
        padding: 0;
        .homeAboutTitle {
          display: unset;
          color: var(--text--green)
        }

        .homeAboutDescription {
          @extend .flexCol;
          padding: 1rem;
          gap: 1rem;
        }
      }
    }
  }
}