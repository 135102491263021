@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

:root {
  /* Colour */
  --black: #000;
  --white: #fff;
  --primary-300: #a9d99f;
  --primary-400: #98d18d;
  --primary-500: #7ec670;
  --primary-600: #73b466;
  --primary-700: #598d50;
  --primary-800: #456d3e;
  --primary-900: #35532f;
  --text--grey: #606060;
  --text--dark: #333232;
  --palm: #c3ee00;
  --menu: #f9f9f9;
  --text--green: #5d9d53;
  --background: #0a562b;
  --secondary-100: #f9f8ed;
  --secondary-200: #f7f5e4;
  --secondary-300: #fdfdf9;
  --gray-900: #42423a;
  --tint: #36ff8c;

  /* Fonts & Sizes */
  --quicksand: 'Quicksand', sans-serif;
  --inter: 'Inter';
  --montserrat: 'Montserrat', sans-serif;

  /* Gradient */
  --gradient: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(106deg, #7ec670 -6.85%, #dcc15f 124.8%);

  /* Width */
  --w--100: 100%;
  --w--90: 90%;
  --w--80: 80%;
  --max--100: 100%;

  /* Alignment & Justfication */
  --center: center;
  --space--between: space-between;
  --start: flex-start;
  --flex: flex;
  --block: block;

  /* Font sizes */
  --title-48: 11vw;
  --title-36: 8.372vw;
  --title-32: 7.5vw;
  --title-28: 6.5vw;
  --text-24: 5.5vw;
  --text-20: 4.6vw;
  --text-18: 4.1vw;
  --text-16: 3.7vw;
  --text-15: 3.5vw;
  --text-14: 3.2vw;
  --text-12: 2.7vw;
  --text-13: 3vw;
}

@media screen and (min-width: 429px) {
  :root {
    ----title-48: 48px;
    --title-36: 36px;
    --title-32: 32px;
    --title-28: 28px;
    --text-24: 24px;
    --text-20: 20px;
    --text-18: 18px;
    --text-16: 16px;
    --text-15: 15px;
    --text-14: 14px;
    --text-12: 12px;
    --text-13: 13px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

a,
button {
  cursor: pointer;
  font-size: 4.3vw;
  text-decoration: none;
}

@media screen and (min-width: 429px) {
  a,
  button {
    font-size: 18px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

menu {
  list-style-type: none;
}

.active {
  font-weight: 800;
}

body {
  margin: 0;
  font-size: var(--text-16);
  line-height: 1.5;
  font-family:
    var(--quicksand),
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 768px) {
  body {
    font-size: 1rem;
  }
}

@media screen and (min-width: 956px) {
  body {
    font-size: 1.1rem;
    line-height: 1.3;
  }
}

svg.mapboxgl-ctrl-geocoder--icon {
  display: none !important;
}

input.mapboxgl-ctrl-geocoder--input {
  padding: 0.5rem 1rem;
  width: 100% !important;
  border: 1px solid var(--primary-300) !important;
  box-shadow: none !important;
  border-radius: 5px;
  height: 70px !important;
}

@media screen and (min-width: 640px) {
  input.mapboxgl-ctrl-geocoder--input,
  .mapboxgl-ctrl-geocoder {
    width: 100% !important;
    max-width: 100% !important;
  }
}

input.mapboxgl-ctrl-geocoder--input:focus {
  outline: none;
  border: 1px solid var(--primary-600);
  font-size: 15px !important;
}

.Toastify {
  z-index: 199999999999999999999999999;
}
