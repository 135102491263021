.stockContainer,
.store {
  padding: 1.5rem 1rem;
  color: var(--gray-900);
  position: relative;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  margin: 11vh auto 0;
  gap: 0.5rem;

  .title {
    text-align: center;
    color: #598d50;
    margin-bottom: 1rem;
    font-size: 20px;
  }

  .storeCard {
    display: flex;
    background-color: #f1edd0;
    border-radius: 10px;
    padding: 0.5rem;
    gap: 1.5rem;
    height: 120px;
    max-width: 500px;
    margin: 0 auto;

    &:nth-of-type(even) {
      background-color: #f7f5e4;
    }

    .splitter {
      display: flex;
      width: 100%;
      gap: 0.5rem;
    }

    .img {
      background: #e9e5c4;
      border-radius: 10px;
      max-width: 95px;
      // height: 85px;
    }

    .productInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        text-transform: capitalize;
        font-size: 15px;
      }

      p {
        font-size: 14px;
      }
    }

    .actionDiv {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;

      .del {
        color: #e84632;
        cursor: pointer;
        font-size: 1.75rem;
      }

      button {
        border: 1px solid #598d50;
        border-radius: 5px;
        padding: 0.5rem;
        background-color: transparent;
        color: #598d50;
        transition: transform 0.2s;

        &:active {
          transform: scale(1.07);
        }
      }
    }
  }

  .empty {
    text-align: center;
    margin-top: 3rem;
    font-size: 1rem;
  }

  .icon {
    font-size: 2.5rem;
    background: var(--gradient);
    background: linear-gradient(90deg, #7ec670 0%, #dcc15f 100%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    border-radius: 50%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    position: fixed;
    bottom: 5%;
    right: 5%;
    transition: transform 0.2s;

    &:active {
      transform: scale(1.1);
    }
  }

  .formDiv {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    background-color: #e9e5c4;
    height: fit-content;
    overflow-y: scroll;
    // height: 250rem;
  }
}

.store {
  margin-top: 2vh;
}