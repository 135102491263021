.videoContainer {
  padding: 0 1rem 1rem;
  .videoDiv {
    border-radius: 10px;
    width: 100%;

    .video {
      border-radius: 10px;
      width: 100%;
    }
  }

  .videoTime {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }

  .videoSubtitle {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .subtitle {
      font-size: 1.15rem;
      color: var(--text--grey);
    }
  }
}
