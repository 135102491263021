.flex {
  display: flex;
}

.flexCol {
  @extend .flex;
  flex-direction: column;
}

.formDiv {
  margin-top: 4rem;
  width: 100%;
  padding: 0 1rem;
  color: #57564c;

  &.myStock {
    margin-top: 2rem;
  }

  .flipper {
    @extend .flex;
    align-items: center;
    gap: 2rem;
    margin-top: 5vh;

    .option {
      input {
        margin-right: 0.5rem;
      }

        @extend .flexCol;
        position: relative;
        padding-left: 1.75rem;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: all 0.2s;
  
        &:hover .input ~ .checkmark {
          background-color: #42423a;
        }
  
        .input {
          position: absolute;
          opacity: 0;
          top: -1px;
          left: -1px;
          height: 25px;
          width: 25px;
          cursor: pointer;
          z-index: 20;
          transition: all 0.2s;
        }
  
        .input.input:checked ~ .checkmark {
          background: transparent;
          padding: 0.15rem;
        }
  
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          border: 2px solid #42423a;
          border-radius: 50%;
  
          &:after {
            content: "";
            position: absolute;
            display: none;
          }
        }
  
        .input:checked ~ .checkmark:after {
          display: block;
        }
  
        .checkmark:after {
          top: 4.75px;
          left: 4.75px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: #42423a;
        }
    }
  }
}

// Form for Farm products
.container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
  width: 100%;
  margin-top: 3vh;
  
  .formSplit {
    display: grid;
    grid-template-columns: 2.5fr 1fr;

    .formGroup {
      input {
        border-right: none;
        border-radius: 5px 0 0 5px;
      }

      select {
        border-radius: 0 5px 5px 0;
      }
    }

    &:nth-last-of-type(3) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .formGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    label {
      margin: 0.5rem 0;
      font-weight: 700; 
    }

    select,
    input,
    textarea {
      width: 100%;
      padding: 0.5rem 1rem;
      border: 1px solid var(--primary-300);
      border-radius: 5px;
      background: #faf9ef;
      font-size: 15px;
      color: var(--gray-900);
      height: 48px;

      &::placeholder {
        color: var(--gray-900)
      }

      &:focus {
        outline: none;
        border: 1px solid var(--primary-600);
      }
    }

    select {
      appearance: none;

      option {
        background-color: #faf9ef;
        padding: 0.5rem;

        &:hover {
          background-color: var(--primary-300 !important);
        }
      }
    }

    .icon {
      position: absolute;
      top: 23%;
      right: 0.5rem;
      color: #b1b1a1;
      font-size: 1.5rem;
    }

    .dropzone {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      border: 2px dashed var(--primary-300);
      border-radius: 4px;
      min-height: 198px;
      background-color: #faf9ef;

      .image {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        .img {
          font-size: 3rem;
          color: var(--primary-300);
        }
      }
    }
    
    .error {
      color: red;
      font-size: 0.8rem;
    }
  }
  
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    padding: 1rem;
    border: 2px dashed var(--primary-300);
    border-radius: 4px;
    min-height: 198px;
    background-color: #faf9ef;

    .map {
      font-size: 3rem;
      color: var(--primary-300);
    }
  }
  
  .button {
    padding: 1rem;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 85%;
    margin: 2rem auto;
    background: var(--gradient);
    transition: transform 0.2s;

    &:active {
      transform: scale(0.9);
    }
  }
}

.cracker {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 600;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease;

  &.hide {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.success {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background:  var(--white);
  border-radius: 30px;
  box-shadow: 0px 8px 20px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  width: 75%;
  line-height: 1.4;
  border: none;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  color: var(--text--grey);
  font-weight: 500;
  padding: 2rem;
  text-align: center;

  .icon {
    font-size: 5rem;
    color: var(--primary-300);
  }

  .link {
    padding: 1rem;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    margin: 1rem auto 1.5rem;
    background: var(--gradient);
    transition: transform 0.2s;

    &:active {
      transform: scale(0.9);
    }
  }

  &.hide {
    display: none;
  }
}