/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

/************/
header {
  width: var(--w--100);
  height: 11vh;
  background-color: rgb(23, 68, 17);
  position: fixed;
  top: 0;
  z-index: 300000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);

  &.scroller {
    background: #598d50;
  }

  .navBar {
    display: var(--flex);
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
    height: inherit;

    .navLogoB {
      display: flex;
      align-items: center;
      max-width: 90%
    }


    .navPre {
      @extend .navBar;
      gap: 3rem;
      padding: 0;
      width: fit-content;

      .exitMenu {
        font-size: 2rem;
        color: #fff;
        cursor: pointer;
      }

      .mobileMenuIcon,
      .arrowIcon {
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--white);
      }

      .navLink {
        display: flex;
        align-items: center;

        .navLogo {
          max-width: 95%;
        }
      }
    }

    .navLinksMob {
      position: absolute;
      top: 10vh;
      left: 0;
      width: 100%;
      overflow: hidden;
      height: 500vh;
      background-color: rgba(52, 82, 46, 0.8);
      backdrop-filter: blur(10px);
      z-index: 100;

      .navList {
        background: white;
        width: 50%;
        height: 100%;
        @extend .flexCol;
        padding: 4rem 1.5rem;
        gap: 1.5rem;
        list-style-type: none;
        font-weight: 700;

        .navLinkItem {
          color: var(--primary-900);
          font-size: 1.15rem;
          transition: all ease-in-out 0.2s;
  
          &:focus {
            transform: scale(1.1);
          }
        }
      }
    }

    .navLinks {
      display: none;
    }

    .scroll {
      position: fixed;
      top: 90%;
      left: 90%;
      font-size: 6vw;
      color: var(--palm);
      opacity: 0.5;
      transition: all ease-in-out 0.3s;
      cursor: pointer;
      z-index: 1000;

      &:hover {
        transform: scale(1.5);
        opacity: 1;
      }

      @media screen and (min-width: 429px) {
        font-size: 1.5rem;    
      }
    }

    .profile {
      display: flex;
      gap: 2rem;
      align-items: center;
      position: relative;

      .search {
        display: flex;
        align-items: center;
        position: relative;

        .navSearch {
          width: 100%;
          position: fixed;
          left: 2.5rem;
          height: inherit;
          border: none;
          color: var(--primary-900);
          background: var(--primary-700);
          font-weight: 500;
          transition: all ease-in-out 0.3s;

          @media screen and (min-width: 768px) {
            width: 100%;
            left: 10%;
          }

          .searchInput {
            width: 90%;
            max-width: 300px;
            height: 40px;
            margin-left: 0.7rem;
            border: 1px solid #a9d99f;
            border-radius: 40px;
            background: white;
            font-weight: 500;
            font-size: var(--text-16);
            padding: 16px 20px;
            outline: none;

            &:focus {
              outline: none;
            }
          }

          .searchBtn {
            position: relative;
            left: -2.3rem;
            top: 0.5rem;
            font-weight: 500;
            border: none;
            background: transparent;
            cursor: pointer;
            outline: none;

            .iconic {
              color: #d2d2c9;
              font-size: var(--title-28);
            }
          }
        }

        .icon {
          cursor: pointer;
          font-size: var(--title-28);

          @media screen and (min-width: 429px) {
            font-size: 1.5rem;    
          }
        }
      }

      .action {
        position: absolute;
        top:  52px;
        left: -50px;
        width: 100%;

        .logout {
          width: 90px;
          padding: 0.25rem;
          border: none;
          color: white;
          background: var(--gradient);
        }
      }

      .note {
        position: relative;
        display: flex;
        .notif {
          position: absolute;
          background-color: #ff4d37;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          top: 0;
          right: 0;
        }
      }

      .icon {
        font-size: 1.5rem;
        font-weight: 500;
        color: white;
        position: relative;
      }

      .cartCount {
        position: absolute;
        top: 0;
        left: 15px;
        background: #ff4d37;
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }

      .profileImg {
        display: block;
        color: white;
  
        .user {
          font-size: 1.5rem;
          display: block;
          color: white;
        }
      }

      .signup {
        width: fit-content;
        padding: 0.75rem 0.5rem;
        background: var(--gradient);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
        color: var(--white);
        font-weight: 500;
        font-size: 4.3vw;
        border: none;
        border-radius: 0.5rem;
        transition: all ease-in-out 0.3s;
  
        &:hover {
          transform: scaleX(1.05);
          opacity: 0.8;
          color: #fff;
        }
      
        &:active {
          transform: scale(1.1);
          opacity: 1;
          color: #000;
        }

        @media screen and (min-width: 429px) {
          font-size: 1.15rem;    
        }
      }
    }

    // Min-width: 768px
    @media screen and (min-width: 768px) {
      .button {
        width: var(--w--80);
        max-width: 180px;
      }

      .scroll {
        top: 85%;
      }
    }

    @media screen and (min-width: 956px) {
      gap: 4rem;

      .navPre {

        .mobileMenuIcon {
          display: none;
        }
  
        .navLink {
          display: var(--block);
  
          .navLogo {
            width: 100%;
          }
        }
      }

      .navLinks {
        display: block;

        .navList {
          display: var(--flex);
          list-style-type: none;
          gap: 2rem;

          .navListItem {
            .active {
              font-weight: 800;
            }

            .navLinkItem {
              display: block;
              color: #fff;
              transition: all ease-out 0.1s;

              &:hover {
                color: var(--palm);
                font-weight: 600;
                transform: scale(1.05);
              }
            }
          }
        }
      }
    }

    @media screen and (min-width: 1024px) {}
  }

  &.scroller {
    .action {
      position: relative;
      top: 9vh;
      right: 0;
      width: 100%;

      .logout {
        width: 90px;
        position: absolute;
        left: 8.7vh;
        top: 1vh;
        padding: 0.25rem;
        border: none;
        color: white;
        background: var(--gradient);
      }
    }
  }

  @media screen and (min-width: 956px) {
    transition: background-color 0.3s ease;

    .scrolled {
      position: fixed;
      background-color: var(--text--green);
    }
  }
}
