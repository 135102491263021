.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex {
  display: flex;
}

.flexCol {
  @extend .flex;
  flex-direction: column;
}

.flexRow {
  @extend .flex;
  flex-direction: row;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

.stockContainer {
  background: var(--secondary-200);
  min-height: 100vh;
  width: 100vw;
  overflow-y: auto;
  padding: 14vh 1rem;

  .title {
    font-size: var(--text-20);
    font-weight: 600;
    margin-bottom: 1rem;
    color: var(--primary-700);
  }

  .cartsDiv {
    @extend .flexCol;
    gap: 0.75rem;

    .storeCard {
      @extend .flex;
      @extend .justContentSpaceBtw;
      border-radius: 10px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.10);
      background: var(--Secondary-secondary-400, #F1EDD0);
      padding: 0.75rem;

      .group {
        @extend .flex;
        gap: 1rem;
      }

      .imgDiv {
        border-radius: 10px;
        background: var(--Secondary-secondary-500, #E9E5C4);
        padding: 0.85rem 14px;
        @extend .flex;
        @extend .alignItemsCenter;

        .img {
          width: 60px;
          height: 80px;
          object-fit: contain;
        }
      }

      .productInfo {
        @extend .flexCol;
        @extend .justContentSpaceBtw;
        color: #42423a;
      }

      .actionDiv {
        @extend .flexCol;
        @extend .justContentSpaceBtw;
        font-weight: 600;

        .del {
          color: rgb(232, 70, 50);
          align-self: flex-end;
          font-size: var(--text-24);
          transition: all 0.2s;

          &:active {
            transform: scale(0.9);
          }
        }

        .addUp {
          border-radius: 8px;
          background: var(--gradient);

          span {
            color: white;
            padding: 0 0.75rem;
          }

          .btnQ {
            border: none;
            background: transparent;
            font-size: var(--text-18);
            font-weight: 600;
            color: white;
            padding: 0.5rem 0.75rem;
            transition: all 0.2s;

            &:active {
              transform: scale(0.9);
            }
          }
        }
      }
    }
  }

  .empty {
    @extend .flexCol;
    @extend .alignItemsCenter;
    @extend .justContentCenter;
    gap: 1rem;
    margin-top: 5rem;

    .icon {
      font-size: var(--title-48);
      color: var(--primary-700);
      height: 80px;
      width: 80px;
      padding: 1rem;
      background-color: white;
      border-radius: 50%;
    }
  }

  .delivery {
    margin-top: 2rem;
    border-top: 1px solid#f1edd0;
    padding: 10px 0 20px;
    color: #42423a;

    .delOption {
      @extend .flexCol;
      gap: 0.5rem;
      margin-top: 0.5rem;

      .paymentInfo {
        margin: 0.5rem 0.15rem 1rem;
        border: 1px solid #d2d2c9;
        border-radius: 16px;
        padding: 0.5rem;

        .bankInfo {
          margin: 0.25rem 0.5rem;
          background: #f1edd0;
          padding: 0.5rem 3rem 0.75rem 0.75rem;
          border-radius: 8px;

          .text {
            @extend .flex;
            @extend .justContentSpaceBtw;
            span {
              font-weight: 600;
              align-self: flex-start;
            }
          }

          .paste {
            @extend .relative;
            .copy {
              position: absolute;
              right: -2rem;
              top: 0;
              transform: translateY(-100%);
              font-size: var(--text-20);
              color: #42423a;
              cursor: pointer;
            }

            span {
              position: absolute;
              right: -2rem;
              top: -3.65rem;
              font-size: 10px;
              background: var(--primary-300);
              // color: white;
              border-radius: 5px;
              padding: 0.25rem;
            }
          }
        }
      }
    }

    label {
      @extend .flexCol;
      position: relative;
      padding-left: 1.75rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      transition: all 0.2s;

      &:hover .input ~ .checkmark {
        background-color: #42423a;
      }

      .input {
        position: absolute;
        opacity: 0;
        top: -0.5px;
        left: 0;
        height: 25px;
        width: 25px;
        cursor: pointer;
        z-index: 20;
        transition: all 0.2s;
      }

      .input.input:checked ~ .checkmark {
        background: transparent;
        padding: 0.15rem;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border: 2px solid #42423a;
        border-radius: 50%;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }

      .input:checked ~ .checkmark:after {
        display: block;
      }

      .checkmark:after {
        top: 5px;
        left: 4.5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #42423a;
      }
    }

    .info {
      width: 100%;
      margin: 0.5rem 0;
      @extend .flexCol;
      gap: 0.5rem;

      .addressDiv {
        margin: 0 1.75rem 0.5rem;
        p {
          max-width: 80%;
        }
      }

      .continue {
        border: 1px solid var(--primary-600);
        background: transparent;
        font-size: 16px;
        border-radius: 8px;;
        color: var(--primary-600);
        padding: 0.5rem 0.75rem;
        transition: all 0.2s;
        max-width: fit-content;
        margin: 0 1.75rem 0.5rem;

        &:active {
          transform: scale(0.9);
          background: var(--gradient);
          color: white;
        }
      }
    }

    .form {
      @extend .flexCol;
      gap: 0.5rem;
      margin: 0 1rem 1rem;

      .formSplit {
        @extend .flex;
        gap: 0.5rem;
      }

      .inputDiv {
        @extend .flexCol;
        width: 100%;
        position: relative;

        input,
        select {
          padding: 0.5rem 1rem;
          border: 1px solid var(--primary-300);
          border-radius: 5px;
          background: #faf9ef;
          font-size: var(--text-15);
          color: #706f62;
          width: 100%;

          &::placeholder {
            color: #706f62;
          }

          &:focus {
            outline: none;
            border: 1px solid var(--primary-600);
          }
        }

        select {
          appearance: none;

          option {
            background-color: #faf9ef;
            padding: 0.5rem;

            &:hover {
              background-color: var(--primary-300 !important);
            }
          }
        }

        .icon {
          position: absolute;
          top: 19%;
          right: 0.5rem;
          color: #b1b1a1;
          font-size: var(--text-24);
        }
      }

      .btn {
        background: var(--gradient);
        color: var(--white);
        padding: 0.5rem 1rem;
        border-radius: 5px;
        font-weight: 500;
        border: none;
        margin-top: 0.5rem;
        transition: all 0.3s ease-in-out;
        width: fit-content;
        align-self: flex-start;

        &:active {
          transform: scale(0.95);
        }
      }
    }

    .pickupAddress {
      margin: 0 1.75rem 1rem;

      a {
        text-decoration: none;
        color: #42423a;
        font-weight: 600;
      }
    }
  }

  .summary {
    .text,
    .total {
      @extend .flex;
      @extend .justContentSpaceBtw;
    }

    .total {
      margin-top: 1rem;
      font-size: var(--text-18);
      font-weight: 600;
    }
  }

  .btnOut {
    background: var(--gradient);
    color: var(--white);
    padding: 1rem;
    border-radius: 5px;
    font-weight: 500;
    border: none;
    display: block;
    margin: 2rem auto 0;
    transition: all 0.3s ease-in-out;
    width: 80%;

    &:active {
      transform: scale(0.95);
    }
  }
}
