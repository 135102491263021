/* Declaring some common styles*/
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

/************/

.container {
  @extend .flexCol;
  min-height: 100vh;
  background: url('../../images/oval_bg.svg'), var(--secondary-300);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
  color: var(--gray-900);

  .angle {
    @extend .absolute;
    top: 2rem;
    left: 1rem;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .title {
    z-index: 1000;
    padding: 0 2rem;
    text-align: center;
    margin-top: 4rem;

    .subtitle {
      font-size: 1.25rem;
      color: #456d3e;
      font-weight: 600;
      margin: 2rem auto 2.5rem;
    }

    .desc {
      max-width: 300px;
      margin: 0.5rem auto 1rem;
    }

    .link {
      align-self: center;
      margin-top: 2rem;

      a {
        color: var(--primary-600);
        text-decoration: none;
        transition: 0.2s;
        font-weight: 700;
        margin-left: 0.5rem;

        &:hover {
          color: var(--gray-900);
        }
      }
    }
  }

  .form {
    @extend .relative;
    @extend .flexCol;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    @extend .justContentCenter;
    // gap: 0.5rem;
    padding: 1rem 2rem 2rem;

    .input {
      border: 0.68px solid var(--primary-300);
      height: 48px;
      background: var(--white);
      border-radius: 10px;
      padding: 0 1rem 0 2.5rem;
      color: var(--primary-900);
      caret-color: var(--primary-900);
      
      &::placeholder {
        color: var(--gray-900);
      }

      &:focus {
        border: 0.68px solid var(--gray-900);
        outline: none;
      }
    }

    .icon {
      @extend .relative;
      top: -32px;
      left: 10px;
      width: 10%;
    }
  }

  .btn {
    background: var(--gradient);
    color: var(--white);
    @extend .flex;
    @extend .alignItemsCenter;
    @extend .justContentCenter;
    gap: 0.5rem;
    border: none;
    margin: 2rem auto 0;
    width: 80%;
    height: 48px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s;

    &:active {
      transform: scale(0.95);
      opacity: 0.8;
    }
  }

  // @media screen and (min-width: 768px) {
  //   flex-direction: row;
  //   margin: 0 auto;
  //   .title {
  //     color: white;
  //     padding: 0 2rem;
  //     font-family: var(--rale);
  //     text-align: center;
  //     margin: auto 0;
  //   }


  // .lift {
  //   position: unset;
  //   top: unset;
  // }
// }
}
