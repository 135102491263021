.button {
  width: 100%;
  max-width: 220px;
  padding: 1rem 2rem;
  background-color: var(--palm);
  border: none;
  border-radius: 0.5rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  transition: all ease-in-out 0.3s;

  &:hover {
    transform: scaleX(1.05);
    opacity: 0.8;
    color: #fff;
  }

  &:active {
    transform: scale(1.1);
    opacity: 1;
    color: #000;
  }

  @media screen and (min-width: 768px) {
    max-width: 250px;
    height: 60px;
    font-size: 1.15rem;
  }

  @media screen and (min-width: 956px) {
    max-width: 200px;
    height: auto;
    font-size: 1rem;
    padding: 1rem;
  }
}
