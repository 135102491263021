/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}
/************/

.container {
  @extend .flexCol;
  height: 100%;
  background: url('../../images/sign_bg.png'), url('../../images/oval_bg.svg'), var(--secondary-300);
  background-repeat: no-repeat, no-repeat;
  background-position: top center, top left;
  background-size: 70%, contain;
  color: var(--gray-900);
  min-height: 100vh;

  .angle {
    @extend .absolute;
    top: 2rem;
    left: 1rem;
    font-size: 1.5rem;
  }

  .logo {
    width: 15%;
    max-width: 50px;
    margin: 1.5rem auto 0;
  }

  .title {
    z-index: 1000;
    padding: 0 2rem;
    text-align: center;
    margin-top: 2rem;

    .subtitle {
      font-size: 1.5rem;
      color: var(--primary-600);
      font-weight: 600;
    }

    .desc {
      max-width: 300px;
      margin: 0.5rem auto 1rem;
    }
  }

  .form {
    @extend .relative;
    @extend .flexCol;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    @extend .justContentCenter;
    padding: 1rem 2rem 2rem;

    .input {
      border: 0.68px solid var(--primary-300);
      height: 48px;
      background: var(--white);
      border-radius: 10px;
      padding: 0 1rem 0 2.5rem;
      color: var(--primary-900);
      caret-color: var(--primary-900);
      
      &::placeholder {
        color: var(--gray-900);
      }

      &:focus {
        border: 0.68px solid var(--gray-900);
        outline: none;
      }
    }

    .icon {
      @extend .relative;
      top: -31px;
      left: 12px;
      width: 10%;
    }

    .btn {
      background: var(--gradient);
      color: var(--white);
      @extend .flex;
      @extend .alignItemsCenter;
      @extend .justContentCenter;
      gap: 0.5rem;
      border: none;
      margin-top: 0.25rem;
      height: 48px;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.2s;

      &:active {
        transform: scale(0.95);
        opacity: 0.8;
      }
    }

    .link {
      align-self: center;
      margin-top: 0.5rem;
      text-align: center;
      line-height: 1.8;

      a {
        color: var(--primary-600);
        text-decoration: none;
        font-weight: 700;
        transition: 0.2s;
        text-align: center;

        &:hover {
          color: var(--gray-900);
        }
      }
    }
  }

  .error {
    color: red;
    padding-top: 1rem;
    @extend .absolute;
    top: 85%;
    text-align: center;
  }

  .success {
    position: absolute;
    @extend .flexCol;
    @extend .justContentCenter;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    z-index: 60000;
    color: var(--darkGreen);
    text-align: center;
    gap: 1rem;

    iframe {
      width: 100%;
      height: 100%;
    }

    a {
      color: var(--darkGreen);
      text-decoration: underline;

      padding-top: 2rem;
      font-size: 0.85rem;
      transition: 0.3s;

      &:hover {
        color: var(--darkGreen);
      }
    }
  }
}
