.pageContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  background: var(--secondary-200);
  min-height: 100vh;
  width: 100vw;
  overflow-y: auto;

  .section {
    overflow-y: auto !important;
    position: absolute;
    min-height: 100vh;
    padding: 13vh 1rem 17vh;
  
    .imgContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .img {
        object-fit: contain;
        width: 100%;
        border-radius: 16px;
      }

      .minImg {
        width: 35%; 
      }

      .priceTag,
      .priceTagD {
        position: relative;
        top: -2rem;
        left: 7%;
        z-index: 100;
        font-size: 1.5rem;
        font-weight: 700;
        color: var(--primary-600);
        
        &:before {
          content: url('../../assets/subtract.svg');
          position: absolute;
          top: -0.37rem;
          left: -1.85rem;
          z-index: -100;
          width: 100%;
        }
      }

      .priceTagD {
        width: 60%;
        background-color: white;
        padding: 0.5rem 1rem;
        border-radius: 8px;

        &:before {
          content: "";
        }
      }
    }

    .productInfo {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      .productDetails {
        color: #323142;
        .productName {
          font-size: var(--text-20);
          font-weight: 700;

          span {
            font-size: var(--text-16);
            font-weight: 600;
          }
        }

        .productMetric {
          color: #706f62
        }
      }

      .productAction {
        .rating {
          display: flex;
          justify-content: space-between;
          padding-bottom: 1rem;

          .filledStar {
            color: var(--primary-600);
            font-size: 1rem;
          }
          
          .lightStar {
            color: var(--primary-600);
          }
        }

        .btn {
          width: 100%;
          padding: 8px;
          font-weight: 600;
          color: var(--white);
          background: var(--gradient);
          border: none;
          border-radius: 8px;
          font-size: var(--text-13);
          cursor: pointer;
          transition: all 0.2s;

          &:active,
          &:hover {
            transform: scale(0.95);
          }
        }
      }
    }

    .desc {
      color: #42423a;
      margin-top: 1.7rem;

      .subheading {
        font-size: var(--text-18);
        font-weight: 700;
      }

      .text {
        font-size: var(--text-18);

        span {
          font-size: var(--text-18);
          font-weight: 700;
          margin-top: 1rem;
          display: block;
        }
      }
    }
  }
}
