.flex {
  display: flex;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

/************/

.slider {
  margin: 7vh 0 0;
  --slide-height: 19rem;
  --slide-spacing: 0.75rem;
  --slide-size: 80%;
  width: 100%;

  .header {
    @extend .flex;
    gap: 1rem;
    @extend .justContentSpaceBtw;
    padding: 1rem 0;
    border-top: 1px solid#f1edd0;

    .seeMoreBtn {
      background: transparent;
      border: none;
      font-weight: 600;
      color: var(--primary-600);
    }
  }

  .viewport {
    overflow: hidden;
  }

  .emblaContainer,
  .allProducts {
    @extend .flex;
    gap: 1rem;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
    width: 400px;
    padding: 0 1.5rem 1.5rem;

    .slide {
      transform: translate3d(0, 0, 0);
      flex: 0 0 var(--slide-size);
      min-width: 0;
      padding-bottom: var(--slide-spacing);
      box-shadow: 0 8px 12px -2px rgba(0, 0, 0, 0.15);
      border-radius: 1.25rem;

      .img {
        box-shadow: 0 8px 12px -2px rgba(0, 0, 0, 0.08);
        border-radius: 1.25rem;
        width: 100%;
        object-fit: cover;
      }

      .productDetails {
        padding: 0.5rem 1rem 1rem;

        .productName {
          font-size: var(--text--14);
          font-weight: 600;
        }

        .productMetric {
          font-size: var(--text-13);
          color: #42423a;
          font-weight: 600;
        }
      }

      .productPrice {
        padding: 0 1rem 0;
        font-weight: 600;
      }
    }
  }

  .allProducts {
    flex-direction: column;
    gap: 1.25rem;
    margin-left: 0;
    width: 100%;
    padding: 0;
  }
}
