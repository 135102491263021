.feedback {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  padding: 9rem 1rem 0;
  margin: 0 auto;
  background: url('../../images/oval_bg.svg');
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;

  @media screen and (min-width: 768px) {
    padding: 9rem 10% 0;
  }
}

.title {
  color: #42423A;
  font-size: 18px;
  max-width: 80%;
  text-align: center;
  margin-bottom: 1rem;
}

.subtitle {
  font-size: 14px;
}

.rating {
  display: flex;
  margin-bottom: 1rem;
}

.emptyStar, .filledStar {
  font-size: 24px;
  cursor: pointer;
}

.emptyStar {
  color: #ccc;
}

.filledStar {
  color: var(--primary-600);
}

.comment {
  margin-bottom: 1rem;
  position: relative;

  .textarea {
    width: 100%;
    max-width: 270px;
    min-width: 250px;
    padding: 10px;
    border: 1px solid var(--primary-600);
    border-radius: 4px;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0 auto;
    color: black;
    font-family: var(--quicksand);
    transition: all 0.3s;
    resize: none;

    &[placeholder] {
      color: #b1b1a1;
    }

    &:focus {
      outline: none;
      border: 1px solid var(--primary-600);
    }
  }

  .submitBtn {
    position: relative;
    top: -5.5rem;
    left: 87%;
    background: none;
    border: none;
    color: var(--primary-600);
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover .icon {
      transform: scale(0.8);
    }
  }
}

.btn {
  background: var(--gradient);
  color: var(--white);
  border-radius: 5px;
  border: none;
  font-weight: 600;
  margin-top: 1.5rem;
  width: 80%;
  max-width: 500px;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
  padding: 1rem;

  &:active {
    transform: scale(0.95);
  }
}
