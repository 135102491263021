.auto {
  margin: 0 auto;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.flexCol {
  @extend .flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.hero {
  @extend .relative;
  @extend .flexAlignCol;
  min-height: 90vh;
  background-color: var(--secondary-300);
  margin-top: 11vh;

  .img {
    width: 100%;
  }

  .heroText {
    margin-top: -120px;
    max-width: 43%;
    padding-left: 16px;
    color: var(--white);
    left: -100px;
    position: relative;
  }

  .heroStore {
    background-color: var(--white);
    margin-top: 1.5rem;
    padding: 1rem;
    width: 60%;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;

    .heroStoreItem {
      @extend .flexAlignCol;
      gap: 0.5rem;

      .action {
        @extend .flex;
        @extend .alignItemsCenter;
        gap: 0.5rem;
        color: var(--primary-600);
        font-weight: 500;
        border: none;
        background: transparent;
        transition: transform 0.2s;

        .icon {
          font-size: 1.25rem;
          background: var(--gradient);
          background: linear-gradient(90deg, #7ec670 0%, #dcc15f 100%);
          -webkit-background-clip: text;
          -moz-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;

          &::before {
            display: initial;
          }
        }

        &:active {
          transform: scale(0.95);
        }
      }
    }
  }
}