.footerContact {
  background-color: var(--secondary-100);
  padding: 3rem 0 16rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;

  @media screen and (min-width: 768px) {
    padding: 4rem 20%;
    
  }

  .footerContactContent {
    position: relative;
    background-color: var(--primary-300);
    width: 90%;
    border-radius: 10px;
    text-align: center;
    max-width: 600px;
    padding: 2rem 1.5rem 6rem;

    .footerContactTitle {
      font-size: 1.25rem;
      color: var(--white);
      margin: 0 auto 0.5rem;
      max-width: 200px;
    }

    .footerContactForm {
      position: relative;
      
      .footerContactInput {
        width: 100%;
        margin: 1rem 0;
        border: none;
        background: #fff;
        height: 48px;
        box-shadow: 2px 12px 10px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 10px;

        &::placeholder {
          color: #706f62;
        }

        &:focus {
          outline: none;
        }
      }

      .footerContactButton {
        background-color: var(--primary-300);
        position: absolute;
        top: 22px;
        right: 0.5rem;
        color: var(--white);
        padding: 0.5rem;
        border: none;
        border-radius: 8px;
        transition: background-color 0.3s;

        &:hover {
          background-color: var(--primary-700);
        }

        &:active {
          background-color: var(--primary-900);
          transform: scale(0.95);
        }
      } 
    }

    .footerContactImage {
      position: absolute;
      width: 60%;
      max-width: 200px;
      left: 50%;
      top: 250px;
      margin-left: -100px;
      margin-top: -25%;

      @media screen and (min-width: 420px) {
        top: 280px;
      }

      @media screen and (min-width: 550px) {
        top: 300px;
      }

      @media screen and (min-width: 750px) {
        top: 320px;
      }
      
      @media screen and (min-width: 768px) {
        top: 300px;
      }
    }
  }
}
