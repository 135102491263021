.auto {
  margin: 0 auto;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.flexCol {
  @extend .flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.container {
  padding: 12.5vh 0 8rem;
  min-height: 90vh;
  @extend .relative;
  background: url('../../assets/bg_shop.svg');
  background-repeat: no-repeat;
  background-position: 0 300px;

  .hero {
    @extend .relative;
    background-color: var(--secondary-300);
    padding: 0 1rem;
    width: 100%;
  
    .img {
      width: 100%;;
    }
  
    .heroText {
      @extend .absolute;
      top: 130px;
      max-width: 47%;
      background: rgba(71, 137, 40, 0.60);
      text-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
      border-radius: 0 20px 20px 0;
      align-self: flex-start;
      padding: 10px 20px;
      color: var(--white);
    }
  }

  .products {
    @extend .flexAlignCol;
    margin-top: 1rem;
    padding: 0 1rem;

    @media screen and (min-width: 768px) {
      margin-top: 5rem;
    }
    
    .cart {
      font-size: var(--title-48);
      color: var(--text--grey);
    }

    .cartText {
      max-width: 50%;
      text-align: center;
      color: var(--primary-600);
    }

    .searchDiv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      width: 100%;

      .search {
        position: relative;
        flex-grow: 1;

        .input {
          border: 1px solid#a9d99f;
          padding: 10px 10px 10px 35px;
          border-radius: 8px;
          height: 48px;
          color: rgb(168, 165, 139);
          min-width: 100%;

          &:active,
          &:focus {
            outline: none;
          }

          // @media screen and (min-width: 768px) {
          //   min-width: 500px;
          // }
        }

        .searchBtn,
        .iconic {
          position: absolute;
          font-size: var(--text-20);
        }

        .iconic {
          left: 0.75rem;
          top: 0.9rem;
        }

        .searchBtn {
          right: 0.5rem;
          top: 0.35rem;
          display: flex;
          align-items: center;
          transform: rotate(90deg);
          padding: 0.5rem;
          background-color: var(--primary-300);
          border: none;
          border-radius: 8px;

          .icon {
            color: white;
          }
        }
      }

      .cta {
        color: #73b466;
        font-weight: 500;
        font-size: var(--text-16);
        font-weight: 700;
        text-decoration: underline;
        padding-bottom: 0.25rem;
      }
    }

    .noResult {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      gap: 1rem;
    }

    .productList {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 1rem;
      gap: 1rem;

      @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
      }

      .productCard {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 20px;
        background: #fff;
        padding: 0.5rem;
        box-shadow: 0 8px 12px -2px rgba(0, 0, 0, 0.15);

        .productImg {
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1rem;
          background-color: var(--white);
          box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.08);

          .img {
            object-fit: contain;
            max-width: 70%;
            min-height: 124px;
          }
        }

        .productDetails {
          padding: 0.5rem;
          display: flex;
          flex-direction: column;

          // .productName {
          //   font-size: 1rem;
          // }

          .productMetric {
            font-size: var(--text-14);
            color: #42423a;
            font-weight: 300;
          }
        }

        .productPrice {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 0.5rem;
          gap: 0.6rem;

          .price {
            font-size: var(--text-20);
            color: #42423a;
            font-weight: 600;
          }

          .buyBtn {
            border: none;
            background: transparent;
          }

          svg {
            display: block;
            cursor: pointer;
          }
        }
      }
    }
  }

  .request {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 60000000000000;
    top: 0;
    left: 0;

    .requestBtn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background:  var(--primary-300);
      padding: 1rem;
      border-radius: 20px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      display: flex;
      width: 65%;
      line-height: 1.4;
      border: none;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      color: var(--white);
      font-weight: 500;
      max-width: 250px;
    }

    .requestForm {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background:  var(--white);
      border-radius: 30px;
      box-shadow: 0px 8px 20px 8px rgba(0, 0, 0, 0.08);
      display: flex;
      width: 75%;
      line-height: 1.4;
      border: none;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      color: var(--text--grey);
      font-weight: 500;

      .formTitle {
        font-size: var(--text-20);
        background-color: var(--primary-300);
        color: white;
        width: 100%;
        padding: 1rem;
        border-radius: 30px 30px 0 0;
      }

      .formGroup {
        padding: 0 1.35rem;

        input {
          width: 100%;
          padding: 0.5rem 1rem;
          border: 1px solid var(--primary-300);
          border-radius: 8px;
          font-size: var(--text-15);
          color: var(--gray-900);
          height: 45px;

          &::placeholder {
            color: var(--gray-900)
          }

          &:focus {
            outline: none;
            border: 1px solid var(--primary-600);
          }
        }
      }

      .submitBtn {
        padding: 1rem;
        color: white;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        width: 55%;
        margin: 0 auto 1.5rem;
        background: var(--gradient);
        transition: transform 0.2s;

        &:active {
          transform: scale(0.9);
        }
      }
    }
  }

  .error {
    color: red;
    font-size: var(--text-13);
    margin-top: 0.5rem;
  }
}