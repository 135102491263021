.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 600;
  top: 0;
  left: 0;
  transition: opacity 0.5s ease;

  &.hide {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.success,
.article {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background:  var(--white);
  border-radius: 30px;
  box-shadow: 0px 8px 20px 8px rgba(0, 0, 0, 0.08);
  display: flex;
  width: 75%;
  line-height: 1.4;
  border: none;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  color: var(--text--grey);
  font-weight: 500;
  padding: 2rem;
  text-align: center;

  .icon {
    font-size: 5rem;
    color: var(--primary-300);
  }

  .link {
    padding: 1rem;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    margin: 1rem auto 1.5rem;
    background: var(--gradient);
    transition: transform 0.2s;

    &:active {
      transform: scale(0.9);
    }
  }

  &.hide {
    display: none;
  }
}

.article {
  color: var(--white);
  padding: 1.25rem;
  border-radius: 8px;
  font-weight: 600;
  background:  var(--primary-300);
  box-shadow: 0px 8px 12px -2px rgba(0, 0, 0, 0.15);
}