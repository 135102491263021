/* Declaring some common styles*/
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

/************/

.container {
  @extend .flexCol;
  min-height: 100vh;
  color: var(--gray-900);
  background: url('../../PalmSchool/images/oval_bg.svg'), var(--secondary-300);
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;

  

  .angle {
    @extend .absolute;
    top: 2rem;
    left: 1rem;
    font-size: 1.5rem;
  }

  .title {
    z-index: 1000;
    padding: 0 2rem;
    text-align: center;
    margin-top: 6rem;

    .subtitle {
      font-size: 1.5rem;
      color: #456d3e;;
      font-weight: 600;
    }

    .desc {
      max-width: 300px;
      margin: 0.5rem auto 1rem;
    }
  }

  .form {
    @extend .relative;
    @extend .flexCol;
    width: 100%;
    @extend .justContentCenter;
    gap: 1rem;
    padding: 1rem 0 2rem;
    max-width: 500px;
    margin: 0 auto;

    .input {
      border: 0.68px solid var(--primary-300);
      height: 48px;
      background: var(--white);
      border-radius: 10px;
      padding: 0 1rem 0 2.5rem;
      color: var(--primary-900);
      caret-color: var(--primary-900);
      
      &::placeholder {
        color: var(--gray-900);
      }

      &:focus {
        border: 0.68px solid var(--gray-900);
        outline: none;
      }
    }

    .btn {
      background: var(--gradient);
      color: var(--white);
      @extend .flex;
      @extend .alignItemsCenter;
      @extend .justContentCenter;
      gap: 0.5rem;
      border: none;
      margin-top: 0.25rem;
      height: 48px;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.2s;

      &:active {
        transform: scale(0.95);
        opacity: 0.8;
      }
    }
  }
}
