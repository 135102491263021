.container {
  position: relative;
  background-color: var(--secondary-300);
  min-height: 100vh;
  max-width: 700px;
  margin: 12vh auto 0;

  .div {
    padding: 1rem;

    .title {
      color: var(--primary-500);
      font-size: var(--title-28);
    }
  }

  .courses {
    background: var(--secondary-200);
    padding: 1rem;
    margin-top: 2rem;
    border-radius: 40px 40px 0 0;

    .courseTitle {
      color: var(--text--grey);
      max-width: 700px;
      margin: 0 auto 1rem;
    }

    .courseDiv {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      padding: 1% 0 5%;
      max-width: 700px;
      margin: 0 auto;

      .course {
        position: relative;
        width: 100%;
        border: none;
        height: 200px;
        text-align: left;
        background-color: var(--white);
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        box-shadow: 5px 6px 24px -14px rgba(0,0,0,1);
        -webkit-box-shadow: 5px 6px 24px -14px rgba(0,0,0,1);
        -moz-box-shadow: 5px 6px 24px -14px rgba(0,0,0,1);

        .courseId {
          position: absolute;
          top: 0.5rem;
          left: 0.5rem;
          color: white;
        }

        .img {
          width: 100%;
          height: 120px;
          object-fit: cover;
          border-radius: 10px 10px 0 0;
        }

        .courseName {
          padding: 0.25rem 0.5rem;
          line-height: 130%;
          font-size: 13px;
        }

        .courseStar {
          padding-left: 0.5rem;

          .filledStar,
          .lightStar {
            font-size: var(--text-12);
          }
        }
      }
    }
  }

  .courseDetails {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    overflow: scroll;

    .courseDetail {
      position: relative;
      background-color: var(--white);
      margin: 3rem auto 0;
      max-height: fit-content;
      display: flex;
      flex-direction: column;
      width: 85%;
      align-items: center;
      border-radius: 20px;
      box-shadow: 5px 6px 24px -14px rgba(255, 255, 255,1);
        -webkit-box-shadow: 5px 6px 24px -14px rgba(255, 255, 255,1);
        -moz-box-shadow: 5px 6px 24px -14px rgba(255, 255, 255,1);
    
      .img {
        width: 100%;
        object-fit: cover;
        height: 200px;
        border-radius: 20px 20px 0 0;
      }

      .courseDetailId,
      .courseName {
        position: absolute;
        top: 3vh;
        left: 1rem;
        color: white;
      }

      .courseName {
        top: 17vh;
        font-weight: 500;
        font-size: 1.25rem;
        padding-right: 0.5rem;
      }

      .courseDesc {
        padding: 1rem;
        text-align: justify;
      }

      .courseInfo {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .courseMode {
          display: flex;
          align-items: center;
          gap: 0.35rem;

          .courseLabel {
            color: var(--primary-600);
            font-size: 1.3 rem;
          }
        }
      }

      .actionDiv {
        display: flex;
        gap: 1rem;
        padding: 1rem;
        align-items: center;
        width: 100%;

        .btn {
          width: 70%;
          margin: 0 auto;
          font-weight: 500;
          background: var(--gradient);
          padding: 0.7rem;
          color: var(--white);
          border-radius: 5px;
          transition: all 0.3s ease-in-out; ;
          border: none;
          text-transform: uppercase;

          &:active {
            transform: scale(0.95);
          }

          &.exit {
            background: transparent;
            border: 1px solid #7ec670;
            padding: 0.65rem;
            color: #7ec670;
            text-transform: unset;
          }
        }
      }

    }
  }
}
