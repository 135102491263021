.container {
  background-color: var(--secondary-100);
  border-radius: 0 0 4rem 0;
  position: relative;

  .hero {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 0 0 0 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    padding: 1rem 1rem 1.5rem;
    text-align: center;
    color: var(--white);
    background: url(../../images/home_bg.png) no-repeat center center/cover;

    @media screen and (min-width: 768px) {
      padding: 3rem 1rem 4rem; 
    }

    .heroLogo {
      margin-top: 10vh;
      max-width: 50px;
    }

    .heroTitle {
      font-size: var(--title-36);
      max-width: 330px;
      margin: 0 auto;

      .tint {
        color: #c3ee00;
      }
    }

    .option {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: justify;
      align-items: center;
      column-gap: 1rem;
      row-gap: 0.5rem;
      margin-top: 0.5rem;

      .optionLink {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        background-color: var(--white);
        padding: 0 0.5rem;
        border-radius: 16px;
        height: 100px;
        transition: all 0.2s ease-in;

        &:active {
          transform: scale(0.95);
          opacity: 0.9;
        }

        .school {
          color: #e84632;
        }

        .track {
          color: #e8a909;
        }

        .store {
          color: var(--primary-700);
        }

        .vest {
          color: #5d6679;
        }

        .optionImg {
          width: 50%;
          height: 43px;
        }

        .optionTitle {
          font-size: 0.75rem;
          text-transform: uppercase;;
        }

        &:nth-of-type(1) {
          background: #ffedeb;
        }

        &:nth-of-type(2) {
          background: #fff8e7;
        }

        &:nth-of-type(3) {
          background: #f2f9f1;
        }

        &:nth-of-type(4) {
          background: #f0f1f3;
        }
      }
    }

    .text {
      max-width: 300px;
      margin-top: 0.5rem;
      font-size: var(--text-18);

      @media screen and (min-width: 768px) {
        max-width: 500px;   
      }
    }

    .divider {
      width: 30%;
      background-color: #7ec670;
      height: 1px;
    }
  }

  .heroAction {
    padding: 1rem 0 5rem;
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .heroActionLink {
      display: block;
      width: 70%;
      max-width: 300px;
      margin: 0 auto;
      font-weight: 700;
      background: var(--gradient);
      padding: 0.75rem;
      color: var(--primary-900);
      border-image: linear-gradient(106deg, #7ec670 -6.85%, #dcc15f 124.8%);
      border-style: solid;
      border-radius: 5px;
      font-size: var(--text-18);
      transition: all 0.2s ease-in-out; ;
      border-width: 1px;

      &:active {
        transform: scale(0.95);
      }
    }

    .heroActionLinkB {
      @extend .heroActionLink;
      background: transparent;
      border: 1px solid #7ec670;
      color: #7ec670;

      &:active {
        transform: scale(0.95);
        border-image: var(--gradient);
        border-style: solid;
        background: var(--gradient);
        color: var(--primary-900);
        opacity: 0.9;
      }
    }
  }
}