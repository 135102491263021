.auto {
  margin: 0 auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex {
  display: flex;
}

.flexCol {
  @extend .flex;
  flex-direction: column;
}

.flexRow {
  @extend .flex;
  flex-direction: row;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}

.stockContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  background: var(--secondary-200);
  min-height: 100vh;
  width: 100vw;
  overflow-y: auto;

  .section {
    overflow-y: auto !important;
    position: absolute;
    min-height: 100vh;
    padding: 17vh 1rem 17vh;
    @extend .flexCol;
    width: 100%;
    color: #42423a;

    .title {
      color: #73B466;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
    }

    .sub {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      text-align: center;
    }
    .cartsDiv {
      @extend .flexCol;
      gap: 0.75rem;
      margin-top: 2rem;

      .storeCard {
        @extend .flex;
        gap: 1rem;
        width: 100%;
        border-radius: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        background: #F1EDD0;
        padding: 0.75rem;
            
  
        .imgDiv {
          border-radius: 10px;
          background: #E9E5C4;
          padding: 0.85rem 14px;
          @extend .flex;
          @extend .alignItemsCenter;

          .img {
            width: 60px;
            height: 80px;
            object-fit: contain;
          }
        }

        .productInfo {
          @extend .flexCol;
          @extend .justContentSpaceBtw;
        }
      }
    }

    .orderInfo {
      width: 100%;
      margin-top: 2rem;
      @extend .flexCol;

      .text,
      .price {
        @extend .flex;
        justify-content: flex-start;
        @extend .alignItemsCenter;

        .tilt {
          width: 130px;
          font-weight: 400;
        }

        &h4.tilt {
          font-weight: 700;
        }
        span {
          font-weight: 600;
          text-transform: capitalize;
        }

        .price {
          font-weight: 700;
        }
      }
    }
  }
}