.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

/************/

.container {
  background: var(--secondary-300);
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  margin-top: 13vh;

  .img {
    background: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    border-radius: 50%;
    padding: 1rem 1.5rem;
  }

  .stockDetails {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    margin-top: 2rem;

    span {
      font-weight: 600;
    }

    p {
      text-transform: capitalize;
    }

    .group {
      display: flex;
      gap: 0.25rem;
    }
  }
}

.editStockDetail {
  padding: 2rem 1rem;
  background: #f7f5e4;
  min-height: 90vh;
  margin-top: 13vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .dropzone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
    border-radius: 4px;

    .image {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;

      .img {
        // font-size: 3rem;
        // color: var(--primary-300);
        height: 150px;
        max-width: 300px;
        object-fit: cover;
      }
    }
  }

  .productImage {
    width: 25%;
  }

  .productName {
    font-size: 1rem;
    text-transform: capitalize;
  }

  .changePicture {
    background: none;
    border: none;
    text-decoration: underline;
    padding-bottom: 0.5rem;
    margin: 0.25rem auto 0.5rem;
    color: var(--primary-600);
  }

  .form {
    @extend .flexCol;
    gap: 1rem;
    position: relative;

    .splitter {
      display: flex;
      gap: 1rem;
  
      .formGroup {
        position: relative;
        width: 100%;
  
        label {
          font-weight: 600;
          font-size: 0.75rem;
        }
  
        input,
        select {
          padding: 0.5rem 1rem;
          border: 1px solid var(--primary-300);
          border-radius: 5px;
          background: #faf9ef;
          font-size: 15px;
          color: var(--gray-900);
          height: 48px;
          width: 100%;
  
          &:focus {
            outline: none;
            border: 1px solid var(--primary-600);
          }
        }

        select {
          appearance: none;
    
          option {
            background-color: #faf9ef;
            padding: 0.5rem;
    
            &:hover {
              background-color: var(--primary-300 !important);
            }
          }
        }
    
        .icon {
          position: absolute;
          top: 23%;
          right: 0.5rem;
          color: #b1b1a1;
          font-size: 1.5rem;
        }
      }
    }

    .formGroup {

      label {
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: 0.25rem;
        display: block;
      }

      input.mapboxgl-ctrl-geocoder--input {
        padding: 0.5rem 1rem;
        border: 1px solid var(--primary-300) !important;
        border-radius: 5px;
        background: #faf9ef !important;
        font-size: 15px;
        color: var(--gray-900);
        height: 48px;
        width: 100%;

        &:focus {
          outline: none;
          border: 1px solid var(--primary-600);
        }
      }
    }

    .control {
      position: relative;
      .mapContainer {
        border: 2px dashed var(--primary-300);
        padding: 0.5rem;
        width: 100%;
        height: 200px;
        border-radius: 20px;
        // margin-top: 20px;
      }
  
      .reCaptureButton {
        @extend .absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
        @extend .flex;
        font-size: 12px;
        color: white;
        @extend .alignItemsCenter;
        @extend .justContentCenter;
        background: rgba(0, 0, 0, 0.43);
        border: none;
        border-radius: 50%;
        transition: transform 0.2s;

        &:active {
          transform: scale(0.9);
        }
      }
    }

    .updateButton {
      background: var(--gradient);
      color: var(--white);
      width: 80%;
      @extend .flex;
      @extend .alignItemsCenter;
      @extend .justContentCenter;
      align-self: center;
      border: none;
      margin: 0.5rem auto 2rem;
      height: 48px;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.2s;
      

      &:active {
        transform: scale(0.95);
        opacity: 0.8;
      }
    }
  }
}