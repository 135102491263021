/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexColReverse {
  display: flex;
  flex-direction: column-reverse
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}
/************/

.aboutContainer {
  @extend .auto;
  
  .aboutOne {
    background-color: var(--white);
    padding: 2rem 1rem;
    color: #49454f;

    .aboutContent {
      @extend .flexCol;
      gap: 1rem;
      @extend .alignItemsCenter;
      text-align: center;

      .aboutHeader {
        font-size: 8vw;
        font-weight: 600;
        color: #236740;
      }
    }

    .aboutImage {
      display: none;
    }

    @media screen and (min-width: 768px) {
      padding: 4rem 2rem;
      width: 95%;
      margin: 0 auto;
    }

    @media screen and (min-width: 956px) {
      width: 80%;
      display: var(--flex);
      justify-content: space-between;
      align-items: center;
      padding: 5rem 4rem;
      margin-top: 3rem;

      .aboutImage {
        display: unset;
        border: 6px solid var(--text--green);
        max-width: 50%;
      }

      .aboutContent {
        align-items: flex-start;
        text-align: left;
        max-width: 40%;
  
        .aboutHeader {
          font-size: 3.5rem;
          font-weight: 600;
        }

        .aboutParagraph {
          font-size: 1.5rem;
        }
      }
  
    }
  }

  .aboutTwo {
    margin: 4rem auto 0;
    width: var(--w--90);
    
    .aboutImage {
      width: 100%;

      @media screen and (min-width: 768px) {
        width: var(--w--100);
        margin: 0 auto;
      }
    }

    @media screen and (min-width: 956px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0.5rem;
      width: var(--w--90);

      .aboutImage {
        max-width: 100%;
      }
    }
  }

  .aboutThree {
    width: var(--w--90);
    @extend .auto;
    @extend .flexCol;
    @extend .alignItemsCenter;
    margin-top: 8rem;

    .aboutImage {
      width: 90%;
    }

    .aboutHeader {
      margin-top: 1rem;
    }

    .aboutParagraph {
      margin: 0.52rem 0;
    }

    @media screen and (min-width: 768px) {
      width: var(--w--80);

      .aboutParagraph {
        margin: 1rem 0 2rem;
      }
    }

    @media screen and (min-width: 956px) {
      width: var(--w--90);
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      line-height: 1.5;

      .aboutImage {
        width: 100%;
        height: 100%;
        object-fit: cover
      }
  
      .aboutHeader {
        margin-top: 0;
        font-size: 2rem;
        line-height: 1.3;
        max-width: 465px
      }

      .aboutParagraph {
        margin: 0.5rem 0;
        max-width: 465px
      }
    }
  }

  .aboutFour {
    @extend .aboutThree;
    color: var(--text--grey);

    .aboutTitle {
      font-size: 7vw;
      color: #3a3a3a;
      border-bottom: 14px solid rgba(196, 240, 0, 0.4);
      line-height: 1;
      margin: 2rem 0 1rem;
      width: 90%;
    }

    .aboutParagraph {
      font-weight: 600;
    }

    .aboutList {
      padding: 0 1rem;
      @extend .flexCol;
      gap: 0.25rem;
      font-weight: 500;
    }

    @media screen and (min-width: 768px) {
      width: var(--w--80);
      
      .aboutTitle {
        font-size: 5vw;
        width: 80%;
      }

      .aboutParagraph {
        font-weight: 500;
      }

      .aboutList {
        font-weight: 400;
        line-height: 1.4
      }  
    }

    @media screen and (min-width: 956px) {
      width: var(--w--90);
      direction: rtl;

      .aboutContent {
        direction: ltr;
        .aboutTitle {
          font-size: 2rem;
          border-bottom: unset;
        }
      }

      .aboutImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .aboutFive {
    @extend .aboutThree;
    @extend .flexCol;
    gap: 1rem;

    .aboutImgDiv {
      @extend .flexCol;
      @extend .alignItemsCenter;

      .aboutImage:first-of-type {
        display: none;
      }
    }

    .aboutContent {
      .aboutTitle {
        color: #5d9d53;
        font-size: 7vw;
      }

      .aboutList {
        padding: 1rem 1.5rem;
        @extend .flexCol;
        gap: 0.25rem;
        font-weight: 500;
      }
    }

    @media screen and (min-width: 768px) {
      .aboutContent {
        .aboutTitle {
          font-size: 5vw;
        }
  
        .aboutList {
          width: 80%;
          font-weight: 400;
        }
      }
    }

    @media screen and (min-width: 956px) {
      .aboutImgDiv {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

  
        .aboutImage:first-of-type {
          display: unset;
          object-fit: cover;
        }

        .aboutImage:last-of-type {
          display: none;
        }
      }

      .aboutContent {
        .aboutTitle {
          font-size: 2rem;
        }
  
        .aboutList {
          width: 100%;
        }
      }
    }
  }

  // Span styles
  .tint {
    color: var(--palm);
  }

  .tintGreen {
    color: var(--text--green);
  }

  .bold {
    font-weight: 600;
  }
}
