/* Declaring some common styles*/
.auto {
  margin: 0 auto;
}

.flexCol {
  display: flex;
  flex-direction: column;
}

.flexColReverse {
  display: flex;
  flex-direction: column-reverse
}

.flexRowReverse {
  display: flex;
  flex-direction: row-reverse
}

.flex {
  display: flex;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

.justContentSpaceBtw {
  justify-content: space-between;
}
/************/

.faqContainer {
  width: 95%;
  @extend .auto;

  .faqHeader {
    font-size: 7vw;
    color: #3a3a3a;
    border-bottom: 14px solid rgba(196, 240, 0, 0.4);
    margin: 2rem 0 1rem;
    width: 80%;
    margin-left: 1rem;
  }

  .faqContent {
    @extend .flexCol;
    gap: 1rem;

    .faq {
      background: #f1f5f2;
      padding: 1rem;
      @extend .flexCol;
      gap: 1rem;

      .faqTitle {
        @extend .flex;
        @extend .alignItemsCenter;
        @extend .justContentSpaceBtw;
        position: relative;
        color: #14183e;
        gap: 2rem;
        width: 90%;

        .flip {
          position: absolute;
          top: 0.25rem;
          left: 105%;
          color: #101828;
          cursor: pointer;
          transition: all ease-in-out 0.1s;

          &:active {
            transform: rotate(90deg);
          }
        }


        .fliper {
          @extend .flip;
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    width: 85%;

    .faqHeader {
      font-size: 6vw;
      width: 80%;
      line-height: 1.2;
    }

    .faqContent {
      @extend .flexCol;
      gap: 1rem;
  
      .faq {
        padding: 1.5rem;
  
        .faqTitle {
          width: 80%;
  
          .flip {
            left: 120%;
          }
        }
      }
    }    
  }

  @media screen and (min-width: 956px) {
    width: 85%;
    margin-top: 200px;

    .faqHeader {
      font-size: 1.5rem;
      width: fit-content;
      border-bottom: 10px solid rgba(196, 240, 0, 0.4);
      line-height: 0.4;
    }

    .faqContent {
      display: grid;
      grid-template-columns: repeat(2, 1fr);

      gap: 1rem;
  
      .faq {
        padding: 1.5rem;
  
        .faqTitle {
          width: 80%;
  
          .flip {
            left: 120%;
          }
        }
      }
    }
  }
}