.container {
  position: relative;
  background-color: #f7f5e4;
  min-height: 100vh;
  padding: 15vh 1rem 0;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    padding: 9rem 10% 0;
  }

  .title {
    font-size: var(--text-18);
    font-weight: 700;
    color: #42423a;
  }

  .flexContent {
    margin-top: 1rem;
    .subheading {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .subtitle {
        color: #7ec670;
        font-size: var(--text-24);
      }
    }

    .form {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .groupDiv {
        border: 1px solid var(--text--grey);
        padding: 1rem 12px;
        border-radius: 10px;
        min-height: 150px;
        min-width: 280px;
      }

      .question {
        display: flex;
        gap: 1rem;
        text-align: justify;
        margin-bottom: 1rem;
        color: #42423a;
      }

      .answer{
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        .option {
          display: block;
          gap: 0.5rem;
          align-items: center;
          position: relative;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          padding-left: 55px;

          &:hover .input ~ .checkmark {
            background-color: rgba(69, 109, 62, 0.4);
          }

          .input {
            position: absolute;
            opacity: 0;
            top: 0;
            left: 2rem;
            height: 25px;
            width: 25px;
            cursor: pointer;
            z-index: 200;
          }

          input.input:checked ~ .checkmark {
            background: transparent;
            padding: 0.15rem;
          }

          .checkmark {
            position: absolute;
            top: 0;
            left: 2rem;
            height: 20px;
            width: 20px;
            border: 2px solid #42423a;
            border-radius: 50%;

            &:after {
              content: "";
              position: absolute;
              display: none;
            }
          }

          .input:checked ~ .checkmark:after {
            display: block;
          }

          .checkmark:after {
            top: 5px;
            left: 5px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #42423a;
          }

          label {
            margin-left: 0.35rem;
            color: #42423a;
          }
        }

        .optionB {
          @extend .option;
          padding-left: 65px;

          .inputB {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            top: 0;
            left: 2.5rem;
            height: 20px;
            width: 20px;
            z-index: 200;
          }

          .checkmarkB {
            position: absolute;
            top: 0;
            left: 2.5rem;
            height: 20px;
            width: 20px;
            border: 2px solid #42423a;

            &:after {
              content: "";
              position: absolute;
              display: none;
            }
          }
          
          .inputB:checked ~ .checkmarkB:after {
            display: block;
          }

          &:hover .inputB ~ .checkmarkB {
            background-color: #42423a;
          }

          .inputB:checked ~ .checkmarkB {
            background-color: #42423a;
          }

          .checkmarkB:after {
            top: 1px;
            left: 5px;
            width: 4px;
            height: 8px;
            border: solid white;
            border-top: 0;
            border-width: 0 3px 3px 0 !important;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }

      .btn {
        background: var(--gradient);
        color: var(--white);
        padding: 0.75rem;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 1.5rem;
        transition: all 0.3s ease-in-out;
        border: 1px solid var(--gradient);
        width: fit-content;
        align-self: flex-end;

        &:active {
          transform: scale(0.95);
        }
      }
    }

    .correct {
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);

      .wrapper,
      .wrapperB {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: var(--white);
        padding: 1rem;
        border-radius: 30px;
        display: flex;
        width: 85%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        text-align: center;

        .text {
          font-size: 1rem;
          max-width: 70%;
          font-weight: 700;
          text-transform: uppercase;
        }

        .correctAnswer {
          border: 1px solid var(--text--grey);
          padding: 0.5rem;
          border-radius: 10px;
          min-height: 150px;
          min-width: 280px;

          .option {
            display: flex;
            gap: 0.5rem;
            align-items: flex-start;

            .input {
              appearance: none;
            }

            .input::before {
              content: url('../../images/mark.png');
              display: inline-block;
              width: 1rem;
              margin-right: 0.5rem;
            }

            .input[type="radio"] {
              color: var(--primary-300);
            }

            label {
              text-align: left;
            }
          }
        }

        .link {
          background: transparent;
          color: var(--primary-300);
          padding: 0.75rem;
          border-radius: 5px;
          font-weight: 500;
          margin-top: 1.5rem;
          border: none;
          transition: all 0.2s ease-in-out;
          display: flex;
          align-items: center;
          width: fit-content;
          align-self: flex-end;

          .icon {
            font-size: 1.5rem;
          }

          &:active {
            transform: scale(0.95);
          }
        }
      }

      .wrapperB {
        padding: 1rem;
        border-radius: 30px;
        gap: 1rem;
        text-align: center;

        .text {
          font-size: 1rem;
          max-width: 100%;
          font-weight: 700;
          text-transform: uppercase;

          &:nth-last-of-type(1) {
            align-self: flex-start;
            font-style: italic;
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
  }
}

button:disabled {
  background: var(--text-grey);
  color: var(--white);
  cursor: not-allowed;
}

.back {
  height: 90vh;
  overflow: scroll;
  margin-top: 4rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  .title {
    font-size: 1.25rem;
    max-width: 80%;
    text-align: center;
  }

  .div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .btn {
    width: 80%;
    font-weight: 500;
    background: var(--gradient);
    padding: 1rem;
    color: var(--white);
    border-radius: 5px;
    transition: all 0.3s ease-in-out; ;
    border: none;

    &:active {
      transform: scale(0.95);
    }
  }
}
