.container {
  position: relative;
  background-color: var(--secondary-300);
  min-height: 90vh;
  // overflow: scroll;
  max-width: 700px;
  margin: 12vh auto 0;

  .div {
    padding: 1rem;

    .title {
      color: var(--primary-500);
      font-size: var(--title-28);
      font-weight: 700;
      max-width: 80%;
    }
  }

  .modules {
    background: var(--secondary-200);
    padding: 1rem;
    margin-top: 2rem;
    border-radius: 40px 40px 0 0;

    .courseTitle {
      color: var(--black);
    }

    .courseDiv {
      display: flex;
      flex-direction: column;
      padding: 1% 0 5%;

      .course {
        position: relative;
        width: 100%;
        display: flex;
        gap: 0.5rem;
        padding: 1rem 0 0.5rem;
        border-bottom: 1px solid rgba(97, 97, 97, 0.5);
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
        }
        
        .courseId {
          position: absolute;
          top: 70%;
          left: 3%;
          color: white;
          font-size: 1.3rem;
          font-weight: 500;
        }

        .img {
          width: 30%;
          height: 100px;
          object-fit: cover;
          border-radius: 10px;
        }

        .courseBrief {
          .courseTitle {
            color: var(--text--grey);
            margin-bottom: 0.35rem;
            font-weight: 700;
          }

          .courseText {
            font-size: var(--text-14);
          }
        }

        .tracker {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          justify-content: space-between;
          margin-top: 0.5rem;

          .trackerTimer {
            color: var(--primary-600);
          }

          .trackerPercent[value] {
            background-color: var(--text-grey);
            height: 3px;
            width: 30%;

            &::-webkit-progress-value {
              background-image: var(--gradient);
              border-radius: 2px;
              background-size: 35px 20px, 100% 100%, 100% 100%;
              -webkit-animation: animate-stripes 5s linear infinite;
              animation: animate-stripes 5s linear infinite;
            }

            &::-webkit-progress-bar {
              border-radius: 2px;
              box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
            }
          }
        }
      }
      
    }
  }

  @-webkit-keyframes animate-stripes {
    100% { background-position: -100px 0px; }
  }
 
  @keyframes animate-stripes {
    100% { background-position: -100px 0px; }
  }

  .img {
    width: 100%;
    object-fit: cover;
    height: 200px;
    border-radius: 20px 20px 0 0;
  }

  .courseDetailId,
  .courseName {
    position: absolute;
    top: 3vh;
    left: 1rem;
    color: white;
  }

  .courseName {
    top: 17vh;
    font-weight: 500;
    font-size: 1.25rem;
    padding-right: 0.5rem;
  }

  .courseDesc {
    padding: 1rem;
    text-align: justify;
  }

  .actionDiv {
    display: flex;
    gap: 1rem;
    padding: 1rem;
    align-items: center;
    width: 100%;

    .btn {
      width: 70%;
      margin: 0 auto;
      background: var(--gradient);
      padding: 0.75rem;
      color: var(--white);
      border-radius: 5px;
      transition: all 0.3s ease-in-out; ;
      border-image: linear-gradient(106deg, #7ec670 -6.85%, #dcc15f 124.8%);
      border-style: solid;
      border-width: 1px;

      &:active {
        transform: scale(0.95);
      }

      &.exit {
        background: transparent;
        border: 1px solid #7ec670;
        color: #7ec670;
      }
    }
  }

  .assessDiv {
    top: 0;
    left: 0;
    width: 100%;
  }
}

button:disabled {
  background: var(--text-grey);
  color: var(--white);
  cursor: not-allowed;
}
