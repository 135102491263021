.auto {
  margin: 0 auto;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.flex {
  display: flex;
}

.flexCol {
  @extend .flex;
  flex-direction: column;
}

.w100 {
  width: 100%;
}

.alignItemsCenter {
  align-items: center;
}

.flexAlignCol {
  @extend .flexCol;
  @extend .alignItemsCenter;
}

.container {
  padding: 13vh 0 8rem;
  min-height: 90vh;
  width: 100%;
  @extend .relative;
  background: #f7f5e4;

  .hero {
    @extend .relative;
    padding: 0 1rem;
  
    .img {
      width: 100%;
    }
  
    .heroText {
      @extend .absolute;
      top: 120px;
      max-width: 200px;
      background: rgba(71, 137, 40, 0.60);
      text-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
      border-radius: 0 20px 20px 0;
      display: flex;
      align-self: flex-start;
      padding: 10px 20px;
      color: var(--white);
    }
  }

  .products {
    // @extend .flexAlignCol;
    padding: 0 1rem;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    
    .productType {
      margin-bottom: 1rem;
      @extend .flex;
      gap: 1.5rem;
      justify-content: center;
      width: 100%;
      margin-top: 2rem;

      .productButton {
        border-radius: 10px;
        border: 1px solid #98d18d;
        color: #98d18d;
        padding: 0.5rem;
        width: 87px;
        font-size: 14px;
        background: transparent;

        &.active {
          background:#98d18d;
          box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.10);
          color: #fff;
        }
      }
    }
  }

  .request {
    // .requestBtn {
    // }

    .requestForm {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background:  var(--white);
      border-radius: 30px;
      box-shadow: 0px 8px 20px 8px rgba(0, 0, 0, 0.08);
      @extend .flexAlignCol;
      width: 75%;
      line-height: 1.4;
      border: none;
      gap: 1rem;
      justify-content: center;
      color: var(--text--grey);
      font-weight: 500;


      .formGroup {
        padding: 0 1.35rem;

        input {
          width: 100%;
          padding: 0.5rem 1rem;
          border: 1px solid var(--primary-300);
          border-radius: 8px;
          font-size: 15px;
          color: var(--gray-900);
          height: 45px;

          &::placeholder {
            color: var(--gray-900)
          }

          &:focus {
            outline: none;
            border: 1px solid var(--primary-600);
          }
        }
      }

      .submitBtn {
        padding: 1rem;
        color: white;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        width: 55%;
        margin: 1rem auto 1.5rem;
        background: var(--gradient);
        transition: transform 0.2s;

        &:active {
          transform: scale(0.9);
        }
      }
    }
  }

  .error {
    color: red;
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
}