.flex {
  display: flex;
}

.flexCol {
  display: flex;
  flex-direction: column;
}


.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.alignItemsCenter {
  align-items: center;
}

.justContentCenter {
  justify-content: center;
}

// Form for Farm products
.container {
  display: flex;
  flex-direction: column;
  // padding: 1rem;
  gap: 0.5rem;
  width: 100%;
  color: #42423a;
  // margin-top: 3vh;

.label {
  font-weight: 700; 
}

.groupA,
.groupB {
  @extend .flexCol;
  gap: 0.5rem;
  padding: 1.5rem 1.25rem;
  border-radius: 16px;
  background: #f3f0d7;
}

.groupB {
  padding: 0.5rem 1.25rem;
  background: unset;
}

  .formGroupB {
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
    // padding: 0.5rem 1.25rem;

    label {
      @extend .flexCol;
      position: relative;
      padding-left: 1.75rem;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      transition: all 0.2s;

      &:hover .input ~ .checkmark {
        background-color: #42423a;
      }

      .input {
        position: absolute;
        opacity: 0;
        top: -1px;
        left: -1px;
        height: 25px;
        width: 25px;
        cursor: pointer;
        z-index: 20;
        transition: all 0.2s;
      }

      .input.input:checked ~ .checkmark {
        background: transparent;
        padding: 0.15rem;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border: 2px solid #42423a;
        border-radius: 50%;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }

      .input:checked ~ .checkmark:after {
        display: block;
      }

      .checkmark:after {
        top: 5px;
        left: 5px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #42423a;
      }
    }

    .formCombo {
      display: flex;
      align-items: center;

      .inputB {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        z-index: 200;
      }

      .checkmarkB {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border: 2px solid #42423a;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }
      
      .inputB:checked ~ .checkmarkB:after {
        display: block;
      }

      &:hover .inputB ~ .checkmarkB {
        background-color: #42423a;
      }

      .inputB:checked ~ .checkmarkB {
        background-color: #98d18d;
        box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.10);
        border: none;
      }

      .checkmarkB:after {
        top: 3px;
        left: 7.5px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-top: 0;
        border-width: 0 3px 3px 0 !important;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  
  .formSplit,
  .formSplitB {
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    .formGroup {
      input {
        border-right: none;
        border-radius: 5px 0 0 5px;
      }

      select {
        border-radius: 0 5px 5px 0;
      }
    }

    &:nth-last-of-type(3) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .formSplitB {
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    gap: 0;
  }

  .formGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    label {
      margin: 0.5rem 0;
      font-weight: 700; 
    }

    select,
    input,
    textarea {
      width: 100%;
      padding: 0.5rem 1rem;
      border: 1px solid var(--primary-300);
      border-radius: 5px;
      background: #faf9ef;
      font-size: 15px;
      color: #706f62;
      height: 45px;

      &::placeholder {
        color: #706f62;
      }

      &:focus {
        outline: none;
        border: 1px solid var(--primary-600);
      }
    }

    select {
      appearance: none;

      option {
        background-color: #faf9ef;
        padding: 0.5rem;

        &:hover {
          background-color: var(--primary-300 !important);
        }
      }
    }

    .icon {
      position: absolute;
      top: 23%;
      right: 0.5rem;
      color: #b1b1a1;
      font-size: 1.5rem;
    }

    .dropzone {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      border: 2px dashed var(--primary-300);
      border-radius: 4px;
      min-height: 198px;
      background-color: #faf9ef;

      .image {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        .img {
          font-size: 3rem;
          color: var(--primary-300);
        }
      }
    }
    
    .error {
      color: red;
      font-size: 0.8rem;
    }
  }

  .control {
    @extend .relative;

    .mapDisc {
      @extend .absolute;
      top: 0.5rem;
      left: 0.5rem;
      background: white;
      color: black;
      padding: 0.5rem;
      border-radius: 4px;
      box-shadow: 0 4px 8px -2px rgba(0, 0, 0, 0.10);
    }
  }
  
  .mapContainer {
    border: 2px dashed var(--primary-300);
    padding: 0.5rem;
    width: 100%;
    height: 200px;
    border-radius: 20px;
    @extend .relative;
  }

  .reCaptureButton,
  .CaptureButtonB,
  .reCaptureButtonB {
    @extend .absolute;
    top: 103vh;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    @extend .flex;
    font-size: var(--text-15);
    padding: 1rem;
    color: white;
    @extend .alignItemsCenter;
    @extend .justContentCenter;
    background: rgba(0, 0, 0, 0.23);
    border: none;
    border-radius: 50%;
    transition: transform 0.2s;
  }

  .reCaptureButtonB,
  .CaptureButtonB {
    // @extend .relative;
    top: 12vh;

    .iconMap {
      font-size: var(--text-18);
    }
  }

  // .farmDiv {
  //   // margin-top: -6rem;
  // }
  
  .button {
    padding: 1rem;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 85%;
    margin: 2rem auto 3rem;
    background: var(--gradient);
    transition: transform 0.2s;

    &:active {
      transform: scale(0.9);
    }

    // &.big {
    //   // margin-top: 0;
    // }
  }
}
